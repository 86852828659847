<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0 list-table"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
<!--          <b-col-->
<!--              cols="12"-->
<!--              md="6"-->
<!--              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"-->
<!--          >-->
<!--&lt;!&ndash;            <label>显示</label>&ndash;&gt;-->
<!--&lt;!&ndash;            <v-select&ndash;&gt;-->
<!--&lt;!&ndash;                v-model="limit"&ndash;&gt;-->
<!--&lt;!&ndash;                :options="limitOptions"&ndash;&gt;-->
<!--&lt;!&ndash;                :clearable="false"&ndash;&gt;-->
<!--&lt;!&ndash;                class="per-page-selector d-inline-block mx-50"&ndash;&gt;-->
<!--&lt;!&ndash;            />&ndash;&gt;-->
<!--&lt;!&ndash;            <label>条</label>&ndash;&gt;-->


<!--          </b-col>-->
          <div class="demo-spacing-0" style="width: 100%">
            <b-alert
                variant="primary"
                show
            >
              <div class="alert-body" >
                <span><strong>勾选产品，表示此产品需要出库</strong> </span>
              </div>
            </b-alert>
          </div>

          <!-- Search -->
<!--          <b-col-->
<!--              cols="12"-->
<!--              md="6"-->
<!--          >-->
<!--            <div class="d-flex align-items-center justify-content-end">-->
<!--              <b-form-input-->
<!--                  v-model="searchKey"-->
<!--                  class="d-inline-block mr-1"-->
<!--                  placeholder="搜索关键字..."-->
<!--              />-->
<!--              <b-button-->
<!--                  variant="primary"-->
<!--                  :to="{ name: 'apps-salesoutbounditem-edit'}"-->
<!--              >-->
<!--                <span class="text-nowrap">添加</span>-->
<!--              </b-button>-->
<!--            </div>-->
<!--          </b-col>-->
        </b-row>

      </div>
      <ValidationObserver ref="observerItemList">
        <b-table
            ref="refListTable"
            class="position-relative"
            :items="searchList"
            responsive
            :fields="tableColumns"
            primary-key="id"
            :sort-by.sync="orderBy"
            show-empty
            empty-text="未找到记录"
            :sort-desc.sync="isSortDirDesc"
            v-model="saveSalesOutboundArray"
        >

          <!-- Columns -->
          <!--        <template #cell(id)="data">-->
          <!--          <b-form-checkbox-->
          <!--              name="user-id"-->
          <!--              v-model="checked"-->
          <!--              :value="data.item"-->
          <!--          >-->
          <!--            #{{data.item.id}}-->
          <!--          </b-form-checkbox>-->
          <!--        </template>-->

          <!--        <b-form-checkbox-->
          <!--            name="check-button"-->
          <!--            switch-->
          <!--            inline-->
          <!--            id="is_gift"-->
          <!--            v-model="data.item.is_gift"-->
          <!--            value="1"-->
          <!--            unchecked-value="0"-->
          <!--        ></b-form-checkbox>-->

          <template #cell(id)="data">
            <b-link
                class="font-weight-bold d-block text-nowrap"
            >
              #{{ data.item.id }}
            </b-link>
          </template>

          <template #cell(name)="data">
            {{data.item.name}}{{isGift(data.item.is_gift)}}
            <feather-icon
                icon="TwitchIcon"
                size="21"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                v-b-tooltip.hover.html="
              '<div class=text-left>'+
              '产品批次：'+data.item.batch_no+
              '<br>'+'商品69码：'+data.item.ext.productcode+
              '<br>'+'开始：'+toDate(data.item.production_date)+
              '<br>'+'结束：'+toDate(data.item.expiry_date)+
              '<br>'+'所在仓库：'+data.item.ourwarehouse_name+
              '<br>'+'负责人：'+data.item.our_contact+
              '<br>'+'负责人电话：'+data.item.our_mobile+
              '<br>'+'地址：'+data.item.our_location+
              '</div>'"
                variant="success"
            />
          </template>



          <template #cell(is_sel)="data">
            <b-form-checkbox
                name="check-button"
                v-model="data.item.is_sel"
                value="1"
                unchecked-value="0"
                @input="toParent()"
            >#{{ data.item.id }}</b-form-checkbox>
          </template>

          <!-- Column: Type -->
          <template #cell(salesoutbounditem_type)="data">
            {{ getCodeLabel('salesoutbounditem_type', data.item.salesoutbounditem_type) }}
          </template>

          <template #cell(time)="data">
            批次:{{ data.item.batch_no }}
            始:{{ toDate(data.item.production_date) }}
            终:{{ toDate(data.item.expiry_date) }}
          </template>

          <template #cell(num)="data">
            <div style="width: 50px" ></div>
            计划:{{ data.item.ext.premax }}/ 已出:{{ data.item.ext.outbound }}/ 可出:{{ data.item.ext.max }}
            <br>
            <!--          <b-button size="sm" @click="rudeceQty(data.item)" style="float: left">-</b-button>-->
            <b-form-input v-model="data.item.qty"
                          @change="blurQty(data.item,$event)"
                          @input="toParent()">
            </b-form-input>
            <!--          <b-button size="sm" @click="addQty(data.item)" style="float: left">+</b-button>-->

          </template>

          <template #cell(is_gift)="data">
            {{ changeIsGit(data.item.is_gift) }}
          </template>

          <template #cell(warehouse)="data">
            <ValidationProvider rules="required" name="目的仓库" #default="{ errors }"
                                :vid="`warehouse_${data.index}`">
              <v-select
                  id="otherwarehouse_name"
                  :options="warehouseArray"
                  :clearable="true"
                  v-model="data.item.otherwarehouse_name"
                  @input="warehouseInput(data.item,$event);toParent(); inputChange(data.item)"
                  :state="errors.length > 0 ? false:null"
                  class="select-size-sm"
                  placeholder="请选择目的仓库"
                  style="width: 200px"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </ValidationProvider>

            <br>
            <v-select
                id="store_name"
                :options="storeArray"
                v-model="data.item.store_name"
                :clearable="true"
                class="select-size-sm"
                @input="storeInput(data.item,$event);toParent(); ; inputChange(data.item)"
                placeholder="请选择店铺"
            />
          </template>

          <template #cell(startTime)="data">
            <!--          {{changeDate(data.item)}}-->
            <div style="width: 200px"></div>
            <b-form-group
                label-cols="4"
                label-cols-lg="3"
                label="出发时间"
                label-for="delivery_time"
                label-size="sm"
                class="mb-1">
              <flat-pickr
                  v-model="data.item.delivery_time"
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                  @input="changeDeliveryTime(data.item,$event);toParent();; inputChange(data.item)"

              />
            </b-form-group>
            <b-form-group
                label-cols="4"
                label-cols-lg="3"
                label="预计到货"
                label-for="arrival_time"
                label-size="sm"
                class="mb-1">
              <flat-pickr
                  v-model="data.item.arrival_time"
                  class="form-control"
                  :config="{ enableTime: true,dateFormat: 'Y-m-d H:i'}"
                  @input="changeArrivalTime(data.item,$event);toParent(); inputChange(data.item)"
              />
            </b-form-group>
          </template>


          <!-- Column: State -->
          <template #cell(state)="data">
            <b-link
                @click="changeState(data.item)"
            >
              <b-badge
                  pill
                  :variant="`light-$ {getCodeColor('state', data.item.state)}`"

              >
                {{ getCodeLabel('state', data.item.state) }}
              </b-badge>
            </b-link>
          </template>

          <!-- Column: Actions -->
          <template #cell(actions)="data">

            <!--          <b-button size="sm" variant="success" @click="saveSalesOutboundItem(data.item)">保存</b-button>-->

            <b-link @click="saveSalesOutboundItem(data.item)">
              <feather-icon
                  icon="SaveIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </b-link>
            <!--          <b-dropdown-->
            <!--              variant="link"-->
            <!--              no-caret-->
            <!--          >-->

            <!--            <template #button-content>-->
            <!--              <feather-icon-->
            <!--                  icon="MoreVerticalIcon"-->
            <!--                  size="16"-->
            <!--                  class="align-middle text-body"-->
            <!--              />-->
            <!--            </template>-->
            <!--            <b-dropdown-item :to="{ name: 'apps-salesoutbounditem-view', query: { id: data.item.id } }">-->
            <!--              <feather-icon icon="FileTextIcon"/>-->
            <!--              <span class="align-middle ml-50">浏览</span>-->
            <!--            </b-dropdown-item>-->

            <!--            <b-dropdown-item :to="{ name: 'apps-salesoutbounditem-edit', query: { id: data.item.id } }">-->
            <!--              <feather-icon icon="EditIcon"/>-->
            <!--              <span class="align-middle ml-50">编辑</span>-->
            <!--            </b-dropdown-item>-->

            <!--          </b-dropdown>-->
          </template>

        </b-table>
      </ValidationObserver>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BFormGroup,BFormCheckbox,BAlert,BIcon, BootstrapVueIcons,
  VBTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {
  avatarText,
  toTime,
  toDate,
  getCodeOptions,
  getCode,
  getCodeLabel,
  getCodeColor,
  second
} from '@core/utils/filter'
import salesoutbounditemUseList from './salesoutbounditemUseList'
import salesoutbounditemStore from './salesoutbounditemStore'
import warehouseStore from "@/views/apps/warehouse/warehouseStore";
import storeStore from "@/views/apps/store/storeStore";
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'
import {useToast} from "vue-toastification/composition";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BFormGroup,
    flatPickr,
    BFormCheckbox,
    BAlert,
    BIcon,
    BootstrapVueIcons,
    VBTooltip,
    useToast,
  },
  methods: {
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('salesoutbounditem/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      warehouseArray: [],
      warehouse: {},
      warehouseId: 0,
      storeArray: [],
      store: {},
      storeId: 0,
      saveSalesOutboundArray:[],
    }
  },
  props: ["outbound_id"],
  setup(props) {
    const toast = useToast()
    // alert(props.outbound_id)
    // Register module
    if (!store.hasModule('salesoutbounditem')) store.registerModule('salesoutbounditem', salesoutbounditemStore)
    if (!store.hasModule('warehouse')) store.registerModule("warehouse", warehouseStore)
    if (!store.hasModule('store')) store.registerModule('store', storeStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('salesoutbounditem')) store.unregisterModule('salesoutbounditem')
      if (store.hasModule('warehouse')) store.unregisterModule('warehouse')
      if (store.hasModule('store')) store.unregisterModule('store')
    })

    const changeIsGit = function (res) {
      if (res === 0) {
        return "否"
      } else {
        return "是"
      }
    }

    const getWareHouse = function (d) {
      store.dispatch('warehouse/searchtype2',).then(res => {
        let a = res.data.data.list
        for (let i = 0; i < a.length; i++) {
          d.push({"label": a[i].warehouse_name, "warehouse_id": a[i].warehouse_id})
        }

      })
    }

    const edit = function () {
      getWareHouse(this.warehouseArray)
      createStore(this.storeArray)
    }

    const warehouseInput = function (val,event) {
      if (event!=null){
        val.otherwarehouse_id = event.warehouse_id
        this.warehouseId = event.warehouse_id
        this.warehouse = event.label
      }else {
        val.otherwarehouse_id = ""
        this.warehouseId = ""
        this.warehouse = ""
        val.otherwarehouse_name=""
      }

    }

    const storeInput = function (val,event) {
      if (event!=null){
        val.store_id = event.store_id
        this.storeId = event.store_id
        this.store = event.label
      }else {
        val.store_id = ""
        this.storeId = ""
        this.store = ""
        val.store_name = ""
      }

    }

    const createStore = function (d) {
      store.dispatch('store/search',).then(res => {
        // data.state = state
        let a = res.data.data.list
        for (let i = 0; i < a.length; i++) {
          d.push({"label": a[i].store_name, "store_id": a[i].store_id})
        }
      })
    }

    const rudeceQty = function (val) {
      store.dispatch('salesoutbounditem/getmax', {itemid: val.outbounditem_id}).then(res => {
        val.num = val.num-1
        if (val.num<=0){
          val.num=0
        }
      })
    }

    const addQty = function (val) {
      store.dispatch('salesoutbounditem/getmax', {itemid: val.outbounditem_id}).then(res => {
        val.num = val.num+1
        if (val.num>res.data.data){
          val.num=res.data.data
        }
      })
    }

    const blurQty = function (val,event) {
      val.is_sel = 1
      const cRegExp = /^[1-9]\d*$/
      if (!cRegExp.test(event)) {
        toast.error('转换数量必须是正整数!')
        val.qty = 0
      }
      store.dispatch('salesoutbounditem/getmax', {itemid: val.outbounditem_id}).then(res => {
        val.num = parseInt(event)
        console.log(res.data.data)
        if (val.qty>res.data.data){
          val.qty=res.data.data
        }
      })
    }

    const saveSalesOutboundItem = function (result) {
      store
          .dispatch('salesoutbounditem/updateOrderItem', {
            itemid: result.outbounditem_id,
            qty: result.qty,
            issel: result.is_sel,
            warehouseid: result.otherwarehouse_id,
            storeid: result.store_id,
            deliverytime: second(result.delivery_time),
            arrivaltime: second(result.arrival_time),
          })
          .then(response => {
            refetchData()
            toast.success("保存成功!")
          })
          .catch((e) => {
            toast.error("保存失败!")
          })

    }

    const changeDeliveryTime = function (val,event) {
      val.delivery_time = event
    }

    const changeArrivalTime = function (val,event) {
      val.arrival_time = event
    }

    const isGift = function (params) {
      if (params===1){
        return "(赠)"
      }else {
        return null
      }
    }

    const toParent = function () {
      this.$emit("table",this.saveSalesOutboundArray)
    }

    const inputChange = function(data) {
      data.is_sel = 1
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = salesoutbounditemUseList({
      outbound_id: props.outbound_id
    })

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      inputChange,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      toTime, toDate,
      second,
      // UI
      changeIsGit,
      getWareHouse,
      edit,
      warehouseInput,
      storeInput,
      createStore,
      rudeceQty,
      addQty,
      blurQty,
      saveSalesOutboundItem,
      changeDeliveryTime,
      changeArrivalTime,
      isGift,
      toParent,

    }
  },
  created() {
    this.edit()
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
